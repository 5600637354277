import { cn } from "@/lib/utils.ts";
import { useActor, useActorRef } from "@xstate/react";
// import { errorScrollingActor } from "~zipdeal-ui/machines/ErrorScrollingMachine.ts";
import { type } from "remeda";
import { ZD_ERRORS } from "~zipdeal-ui/globalColors.ts";

import React, {
	createContext,
	useState,
	useContext,
	useEffect,
	useRef,
	useId,
} from "react";

type ErrorContextType = { addErrorPosition; scrollToLeastY };
// Create a new context
const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

// Define the provider component
export const ErrorScrollingProvider = ({ children }) => {
	const [errorPositions, setErrorPositions] = useState<
		{ yPosition: number; id: string }[]
	>([]);

	const addErrorPosition = ({ id, yPosition }) => {
		setErrorPositions((prevPositions) => {
			console.log({ prevPositions, id, yPosition });
			return [...prevPositions, { id, yPosition }];
		});
	};

	const scrollToLeastY = () => {
		console.log({ errorPositions });
		const leastY = Math.min(
			...errorPositions.map((position) => position.yPosition),
		);

		console.log({ leastY, errorPositions });
		window.scrollTo({ top: leastY, behavior: "smooth" });
		setErrorPositions([]);
	};

	return (
		<ErrorContext.Provider value={{ addErrorPosition, scrollToLeastY }}>
			{children}
		</ErrorContext.Provider>
	);
};

export const useErrorScrolling = () => {
	const context = useContext(ErrorContext);
	if (context === undefined) {
		throw new Error(
			"useErrorScrolling must be used within a ErrorScrollingProvider",
		);
	}
	return context;
};

// Modify the ErrorMessage component to use the context
export const ErrorMessage = ({
	elementInError,
	erroredElementMsg,
	stringContext = {},
	translation = (i) => i,
	preserveSpace = false,
	className = "",
}) => {
	const errorRef = useRef<HTMLDivElement>(null);

	const id = useId();

	// const {} = useActor(errorScrollingActor);
	const { addErrorPosition, scrollToLeastY } = useErrorScrolling();

	useEffect(() => {
		if (elementInError && errorRef.current) {
			const yPosition =
				errorRef.current?.getBoundingClientRect().top + window.pageYOffset;

			addErrorPosition({ id, yPosition });
		}
	}, [elementInError]);

	// rest of the component...
	if (preserveSpace)
		return (
			<div className={"relative inline-block"}>
				<span
					className={cn({
						["rounded p-1 text-sm font-thin"]: true,
						[ZD_ERRORS.TEXT]: true,
					})}
				>
					{elementInError ? (
						translation(erroredElementMsg, stringContext)
					) : (
						<span>&nbsp;</span>
					)}
				</span>
			</div>
		);
	if (elementInError) {
		const translated = translation(erroredElementMsg, stringContext);
		const hasContent = translated?.trim().length > 0;

		return (
			<div
				ref={errorRef}
				className={cn({
					["absolute -bottom-9 z-10 inline-block"]: true,
					["text-zd-red-400/80"]: true,
					[className]: !!className,
				})}
			>
				<div className="absolute -top-[.1rem] left-3 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform">
					<svg
						viewBox="0 0 12 6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M6 0L12 6H0L6 0Z" className={"fill-current"} />
					</svg>
				</div>
				<div className={"relative inline-block"}>
					<span
						className={cn({
							// [`absolute top-0.5 bg-white`]: true, //WIP for new error messages
							// [ZD_ERRORS.TEXT]: true,
							["text-zd-red-500/80"]: true,
							["rounded px-2 py-1 text-sm font-thin"]: true,
							// [`border border-zd-red-500/40 bg-red-50/90`]: true,
							//////////
							"border-none bg-red-400/90 text-white": hasContent,
							"shadow-sm shadow-zd-red-600/80": hasContent,
						})}
					>
						{translated}
					</span>
				</div>
			</div>
		);
	}

	return null;
};
